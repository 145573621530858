import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Page, SearchBar } from 'components';
import { Header, Results } from './components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Alert } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  alert: {
    marginTop: '20px'
  }
}));

const CooperativeClass = () => {
  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('userData'));
  if (user?.permission?.cooperatives?.read === false) {
    window.location.replace('/home')
  }

  const [units, setUnits] = useState([]);
  const [unitsToShow, setUnitsToShow] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [permission, setPermission] = useState(false);
  let [loading, setLoading] = useState(true);

  const [alertMessage, setAlertMessage] = useState({ "message": '', "type": '' });

  const handleClickOpen = (id) => {
    setCompanyId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setCompanyId('');
    setOpen(false);
  };

  const getUnits = () => {
    (async () => {
      try {
        const { data: units } = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).get(`/company/v1/getcooperative`);
        let sortUnits = units.sort((a, b) => {
          if (a.name === b.nameD)
            return 0;
          if (a.name < b.name)
            return -1;
          if (a.name > b.name)
            return 1;
        });
        
        console.log(sortUnits);
        
        setUnits(sortUnits);
        setUnitsToShow(sortUnits);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error);
      }
    })();
  };

  const removeUnit = () => {
    (async () => {
      setOpen(false);
      try {
        await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/company/delete', { id: companyId });
        getUnits();
        setAlertMessage({ "message": 'Removido com suceso!', "type": 'success' });
      } catch (e) {
        setAlertMessage({ "message": 'Falha ao remover!', "type": 'error' });
      }
    })();
  };

  useEffect(() => {
    getUnits()
  }, [])

  useEffect(() => {
    const { permission } = JSON.parse(localStorage.getItem('userData'))
    setPermission(permission.units.write)
  }, [])

  const handleSearch = () => {
    if (searchData === '') {
      setUnitsToShow(units);
    } else {
      const unitsSearch = [];
      units.forEach((company) => {
        if (
          (company.name && company.name.toLowerCase().includes(searchData.toLowerCase())) ||
          (company.cnpj && company.cnpj.toLowerCase().includes(searchData.toLowerCase())) ||
          (company.ncontract && company.ncontract.toLowerCase().includes(searchData.toLowerCase()))
        ) {
          unitsSearch.push(company)
        }
      });
      setUnitsToShow(unitsSearch);
    }
  };

  const handleSearchData = (e) => {
    setSearchData(e.target.value)
  };

  return (
    <Page className="p-3" title="Cooperativas">
      <Header />
      <SearchBar
        onSearch={handleSearch}
        onSearchData={handleSearchData}
        searchData={searchData}
      />
      {alertMessage.message && (
        <Alert
          className={classes.alert}
          message={alertMessage.message}
          variant={alertMessage.type}
        />
      )}

      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 30 }}>
          <CircularProgress />
        </div>
      ) : null}

      {unitsToShow && (
        <Results
          className={classes.results}
          data={unitsToShow}
          onRemoveButton={handleClickOpen}
          permission={permission}
        />
      )}
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="alert-dialog-title">{'Remover'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja realmente remover essa empresa?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Não
          </Button>
          <Button autoFocus color="primary" onClick={removeUnit}>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default CooperativeClass;

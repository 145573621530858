import React, { useState, useEffect, useRef } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const SearchLocationInput = (props) => {
  const { setLocation, addr } = props;

  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: ['br', 'us', 'py', 'ar'] } }
    );
    autoComplete.setFields([
      'address_components',
      'formatted_address',
      'geometry'
    ]);
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);

    setLocation(query, addressObject.geometry.location.lat(), addressObject.geometry.location.lng());
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    if (addr) {
      setQuery(addr);
    }
  }, [addr]);

  return (
    <>
      <FloatingLabel
        controlId="floatingAddress"
        label="Endereço"
        className="mb-3"
      >
        <Form.Control
          type="text"
          ref={autoCompleteRef}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Endereço"
          value={query}
        />
      </FloatingLabel>
    </>
  );
};

export default SearchLocationInput;

import React, { useState, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Typography } from '@material-ui/core';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page } from 'components';
import { Header, Results } from './components';

import { makeStyles } from '@material-ui/styles';

import { Card, CardContent, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  aboutAuthor: {
    marginTop: theme.spacing(3)
  },
  aboutProject: {
    marginTop: theme.spacing(3)
  },
  projectCover: {
    marginTop: theme.spacing(3)
  },
  projectDetails: {
    marginTop: theme.spacing(3)
  },
  preferences: {
    marginTop: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(3)
  },
  printbtn: {
    right: '0',
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const EquipmentsDetail = (props) => {
  const classes = useStyles();
  const [model, setModel] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    const { model } = props.match.params;
    setModel(model);

    let metrics = JSON.parse(localStorage.getItem('equipMetrics'));
    let versions = Object.keys(metrics[model].softVesions);
    (async () => {
      const { data } = await axios(
        process.env.REACT_APP_API_GLOBAL_SERVER,
        getToken()
      ).post(`/equipment/getserie`, { serie: model });

      let equipments = data.data;

      let formattedData = [];
      for (let i in versions) {
        let j = {};
        j.version = versions[i];
        j.equipments = equipments.filter(
          (item) => item.versaosw === versions[i]
        );
        formattedData.push(j);
      }
      setData(formattedData);
    })();
  }, []);

  const componentRef = useRef();

  return (
    <Page className={classes.root} title={'Equipamentos'}>
      <Header />
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <Divider />
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h3" style={{ margin: 10 }}>
              {model.toUpperCase()}
            </Typography>
          </div>
          <Divider />
          <div ref={componentRef} className={classes.printarea}>
            {data && <Results data={data} />}
          </div>
        </CardContent>
      </Card>
    </Page>
  );
};

export default EquipmentsDetail;

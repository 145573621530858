import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';

import { Button } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  rmvButton: {
    borderColor: '#FE2020',
    color: '#FE2020',
    marginLeft: '10px',
    background: 'transparent'
  }
}));

const Results = (props) => {
  const { className, data, permission, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const [selectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleClick = (serial) => {
    history.push(`/support/${serial}`);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {data.length} registros encontrados. Página {page + 1} de{' '}
        {Math.ceil(data.length / rowsPerPage)}
      </Typography>
      <Card>
        <CardHeader title="Disponíveis para conexão" />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className='col-sm-2'>SERIAL</TableCell>
                    <TableCell align="center">ENDEREÇO</TableCell>
                    <TableCell align="center">MODO</TableCell>
                    <TableCell align="center">PLANO</TableCell>
                    <TableCell align="center">DIAS PARA ATIVAÇÃO</TableCell>
                    <TableCell align="center">VERSÃO DE SOFTWARE</TableCell>
                    <TableCell align="center" className='col-sm-2'>AÇÃO</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((data) => (
                      <TableRow
                        hover
                        key={data._id}
                        selected={selectedData.indexOf(data._id) !== -1}>
                        <TableCell align="center">{data?.serialnumber || '--'}</TableCell>
                        <TableCell align="center">{data?.address?.formatedAddr ? data.address.formatedAddr.length < 50 ? data?.address?.formatedAddr : data.address.formatedAddr.slice(0, 50) + "..." : '---'}</TableCell>
                        <TableCell align="center">{data?.mode === "RENTAL" ? "Locação" : "Proprietário"}</TableCell>
                        <TableCell align="center">{data?.plan ? data?.plan : "NORMAL"}</TableCell>
                        <TableCell align="center">{data?.daystoactivate ? parseInt(data.daystoactivate, 16) : "--"}</TableCell>
                        <TableCell align="center">{data?.versaosw ? data.versaosw : ""}</TableCell>
                        <TableCell align="center">
                          <Button
                            variant='success'
                            onClick={() => {
                              handleClick(data?.serialnumber)
                            }}
                            size="sm"
                          >
                            Conectar
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={data.length}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count} registros`
            }
            labelRowsPerPage="Linhas por página:"
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 20, 40]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired
};

Results.defaultProps = {
  data: []
};

export default Results;

import React, { useState } from 'react';

import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';

import { Page, NotifyModal, ModalAlert } from 'components';
import { Header, Form } from './components';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3, 3, 6, 3)
  },
  aboutProject: {
    marginTop: theme.spacing(3)
  }
}));

const UnitsAdd = () => {
  const classes = useStyles();

  const [units, setUnits] = useState({
    name: '',
    cnpj: '',
    fone: '',
    email: '',
    typeCompany: false
  });

  const [type, setType] = useState({ "color": "", "status": "" });
  const [modalShow, setModalShow] = useState(false);
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [message, setMessage] = useState("");

  const handleModalAlert = (msg) => {
    setMessage(msg);
    setShowModalAlert(true)
  }

  const handleAlertModalSuccess = (msg) => {
    setType({ "color": "#198754", "status": msg })
    setModalShow(true)
  }

  const handleAlertModalError = (msg) => {
    setType({ "color": "red", "status": msg })
    setModalShow(true)
  }


  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    setUnits(values => ({
      ...values,
      [field]: value
    }));
  };

  const updateAddressAndLocation = (newAddress, newLat, newLng) => {
    setUnits(prevUnits => ({
      ...prevUnits,
      address: newAddress,
      location: {
        lat: String(newLat),
        lng: String(newLng)
      }
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!units.name) {
        handleModalAlert('Preencha o Campo "Nome da Unidade"');
        return
      }

      if (!units.address) {
        handleModalAlert('Preencha o campo "Endereço"');
        return
      }

      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/company/create', units);
      handleAlertModalSuccess("Adicionado com sucesso!");
      setUnits({
        name: '',
        cnpj: '',
        fone: '',
        email: '',
        typeCompany: false
      });
    } catch (e) {
      handleAlertModalError('Falha ao adicionar!');
    }
  };

  return (
    <Page className={classes.root} title="Unidades">
      <Header />

      <ModalAlert
        message={message}
        showModalAlert={showModalAlert}
        onHide={() => setShowModalAlert(false)}
      />

      <NotifyModal
        type={type}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <Form
        fieldChange={handleFieldChange}
        onSubmit={handleSubmit}
        units={units}
        setLocation={updateAddressAndLocation}
      />

    </Page>
  );
};

export default UnitsAdd;

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalAlert = ({ message, showModalAlert, onHide }) => {

  return (
    <Modal show={showModalAlert} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Atenção</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAlert;

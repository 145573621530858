import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useParams, useHistory } from 'react-router-dom';
import forge from 'node-forge';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import Terminal from '../Terminal';
import { Alert } from 'react-bootstrap';
import Header from './components/Header';
import dateformat from 'utils/dateformat';
import { getToken } from 'actions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  aboutProject: {
    marginTop: theme.spacing(3)
  },
  actions: {
    marginTop: theme.spacing(3)
  },
  button: {
    marginBottom: '20px',
    marginRight: '20px'
  },
  dialogList: {
    textAlign: 'center',
    marginRight: '80px',
    marginLeft: '80px'
  },
  alert: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  ativaction: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(2)
  },
  label: {
    marginBottom: theme.spacing(3)
  },
  typography: {
    marginBottom: theme.spacing(2)
  }
}));

const wsUrl = process.env.REACT_APP_API_MONITORA.replace('https', 'wss').replace('http', 'ws');
const refreshRate = 15000
let socket;
let publicKey;

const Conected = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const requestRef = useRef(null);

  const serialNumber = useParams().id;
  const { className, ...rest } = props;

  const user = JSON.parse(localStorage.getItem('userData'))
  if (user && user.permission?.support?.read === false) {
    window.location.replace('/home')
  }

  const [imageSrc, setImageSrc] = useState('');
  const [streamInternalCam, setStreamInternalCam] = useState(false);
  const [imageCamSrc, setImageCamSrc] = useState('');
  const [connectionStabished, setConnectionStabished] = useState(false)
  const [inputValueAlert, setInputValueAlert] = useState('');
  const [response, setResponse] = useState('Console\n');
  const [equipConfig, setEquipConfig] = useState({
    'serial': '---',
    'webcam': false,
    'freq_v': '---',
    'serv_e': '---',
    'serv_m': '---',
    'serv_d': '---',
    'calib_id': '---',
    'calib': '---',
    'eixo_x': '---',
    'eixo_y': '---',
    'eixo_z': '---',
    'v_firmware': '---',
    'v_firmware_osc': '---',
    'activation_date': '---',
    'activation_days': '---',
    'volume_corr_densidade': '---',
    'densidade_esfera': '---',
    'offset_bal1': '---',
    'offset_bal2': '---',
    'cross_check': '---',
    'sw': '---',
    'sws': '---'
  })

  const handleSubmit = (inputValue) => {
    if (!connectionStabished) return;

    const command = {
      type: 'message',
      room: serialNumber,
      content: {
        command: 'process',
        parameters: {
          command: inputValue
        }
      }
    };
    socket.send(JSON.stringify(command));
  };

  const handleSubmitAlert = (event) => {
    if (!inputValueAlert || !connectionStabished) return;

    event.preventDefault();
    const command = {
      type: 'alert',
      room: serialNumber,
      msg: inputValueAlert
    };
    socket.send(JSON.stringify(command));

    setInputValueAlert('');
  };

  const handleNewFrame = (imgData) => {
    requestRef.current = requestAnimationFrame(() => {
      setImageSrc(`data:image/png;base64,${imgData}`);
    });
  };

  const handleConnect = () => {
    let pingInterval
    // Criar uma nova instância WebSocket
    socket = new WebSocket(wsUrl);

    pingInterval = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: 'ping' }));
      }
    }, 20000);

    socket.onopen = () => {
      setConnectionStabished(true)
    };

    socket.onmessage = (event) => {
      const data = event.data;
      let json_msg = JSON.parse(data);

      if (json_msg.type === "invalid_user") {
        if (json_msg?.msg) {
          alert(json_msg?.msg)
        } else {
          alert("Sem permissão!");
        }
        handleDisconnect();
      }

      if (json_msg.type === "valid_user") {
        setConnectionStabished(true)
        handleLoadData()
      }

      if (json_msg.type === "desconnect") {
        setConnectionStabished(false)
      }

      if (json_msg.type === "connected") {
        console.log('connected');

        setConnectionStabished(true)
        handleLoadData()
      }

      if (json_msg.pubkey) {
        publicKey = json_msg.pubkey;

        const now = new Date();
        const time = now.toISOString().replace(/T/, ' ').replace(/\..+/, '');

        socket.send(
          JSON.stringify({
            type: 'join',
            room: serialNumber,
            user_id: user?._id,
            name: user?.name,
            email: user?.email,
            client: 'client',
            token: getToken(),
            content: { name: `${user.email}-API-${time}` },
          }));

        //handleLoadData()
      } else {
        if (json_msg?.content?.img) {
          handleNewFrame(json_msg.content.img);
          return;
        }

        if (json_msg?.content?.text) {
          requestAnimationFrame(() => {
            setResponse(json_msg.content.text);
          });
          return;
        }

        if (json_msg?.content?.img_cam) {
          requestAnimationFrame(() => {
            setImageCamSrc(`data:image/png;base64,${json_msg.content.img_cam}`);
          });
          return;
        }

        if (json_msg?.content?.network_config) {
          console.log("network_config", json_msg?.content?.network_config);
          // setNetworkConfig(json_msg.content.network_config);
          return;
        }

        if (json_msg?.content?.equip_config) {
          requestAnimationFrame(() => {
            setEquipConfig(json_msg.content.equip_config);
          });
          return;
        }
      }
    };

    socket.onclose = () => {
      clearInterval(pingInterval)
    };
  };

  const handleLoadData = () => {
    socket.send(
      JSON.stringify({
        type: 'message',
        room: serialNumber,
        content: {
          command: 'load_data',
          parameters: {}
        },
      })
    );

    socket.send(
      JSON.stringify({
        type: 'connect_support',
        room: serialNumber,
        hidden: user.master ? user.master : false
      })
    );
  }

  const handleImageClick = (event) => {
    if (!connectionStabished) return;

    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    socket.send(
      JSON.stringify({
        type: 'message',
        room: serialNumber,
        content: {
          command: 'click',
          parameters: {
            x: x,
            y: y
          }
        }
      })
    );
  };

  const sendGetScreen = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: 'connect_support',
          room: serialNumber,
          stream_internal_cam: streamInternalCam,
          hidden: user.master ? user.master : false
        })
      );
    }
  };

  useEffect(() => {
    const interval1 = setInterval(() => {
      sendGetScreen();
    }, refreshRate);
    return () => {
      clearInterval(interval1);
    };
  }, [socket, streamInternalCam]);

  // Função para desconectar o WebSocket
  const handleDisconnect = () => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(
        JSON.stringify({
          type: 'desconnect_support',
          room: serialNumber
        })
      );
      socket.close();
      setConnectionStabished(false);
    }
    history.push('/support');
  };

  useEffect(() => {
    handleConnect();
    return () => {
      if (socket) socket.close();
    };
  }, []);

  return (
    <Page className={classes.root} title="Socket">
      <Header handleDisconnect={handleDisconnect} />

      {connectionStabished ?
        <Alert variant="success" className='mt-2 mb-2 p-2'>
          <p style={{ marginBottom: 0 }}>
            Conectado
          </p>
        </Alert>
        :
        <Alert variant="danger" className='mt-2 mb-2 p-2'>
          <p style={{ marginBottom: 0 }}>
            Desconectado
          </p>
        </Alert>
      }

      <Row>
        <Col className="p1 mt-2 mb-2">
          <div
            style={{
              width: '816px',
              height: '496px',
              border: '8px solid',
              borderRadius: '5px',
              borderImage: 'linear-gradient(to right, black, gray)', // Gradiente da borda
              borderImageSlice: 1, // Faz o gradiente preencher a borda
              padding: '10px', // Espaçamento interno
              boxSizing: 'border-box', // Inclui a borda no cálculo da largura e altura
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            <div>
              {!imageSrc ? (
                <span style={{ color: 'black' }}>
                  Aguardando imagem...
                </span>
              ) : (
                <img
                  onClick={handleImageClick}
                  src={imageSrc}
                  style={{
                    width: '800px',
                    height: '480px',
                    objectFit: 'cover',
                    cursor: connectionStabished ? 'pointer' : 'none',
                    opacity: connectionStabished ? 1 : 0.5,
                    filter: connectionStabished ? 'none' : 'grayscale(100%)',
                  }}
                />
              )}
            </div>

          </div>
        </Col>
        <Col className='mt-2 mb-2'>
          <div className="p2" style={{ height: '496px', overflowY: 'auto' }}>
            <Terminal
              onCommandSubmit={handleSubmit}
              response={response}
              connectionStabished={connectionStabished}
              user={user}
            />
          </div>
        </Col>
      </Row>

      <div
        className="row flex-grow-1 mt-2"
        style={{ minHeight: '240px' }}
      >
        {/* Linha inferior com 3 colunas */}
        <div className="col-md-6 d-flex flex-column">
          <div className="p-2 bg-light border flex-grow-1 d-flex">
            <table className="table table-sm table-bordered table-striped flex-grow-1 m-0">
              <tbody>
                <tr>
                  <th className="text-center align-middle" scope="col">SERIAL</th>
                  <th className="text-center align-middle" scope="col">FREQUÊNCIA (V)</th>
                  <th className="text-center align-middle" scope="col">ID CALIBRAÇÃO</th>
                  <th className="text-center align-middle" scope="col">CROSS CHECK</th>
                </tr>
                <tr>
                  <td className="text-center align-middle">{equipConfig.serial}</td>
                  <td className="text-center align-middle">{equipConfig.freq_v}</td>
                  <td className="text-center align-middle">{equipConfig.calib_id}</td>
                  <td className="text-center align-middle">{equipConfig.cross_check === 1 ? 'SIM' : equipConfig.cross_check === 0 ? 'NÃO' : '--'}</td>
                </tr>
                <tr>
                  <th className="text-center align-middle" scope="col">DATA DE ATIVAÇÃO</th>
                  <th className="text-center align-middle" scope="col">DIAS DE ATIVAÇÃO</th>
                  <th className="text-center align-middle" scope="col">SOFTWARE</th>
                  <th className="text-center align-middle" scope="col">SOFTWARE BACKUP</th>
                </tr>
                <tr>
                  <td className="text-center align-middle">{equipConfig.activation_date ? dateformat(equipConfig.activation_date, true) : ''}</td>
                  <td className="text-center align-middle">{equipConfig.activation_days}</td>
                  <td className="text-center align-middle">{equipConfig.sw}</td>
                  <td className="text-center align-middle">{equipConfig.sws}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-3 d-flex flex-column">
          <div className="p-2 bg-light border flex-grow-1">
            <Row className="d-flex flex-column align-items-center">

              <Col className="w-100 mb-2">
                <Form.Control
                  onChange={(e) => setInputValueAlert(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmitAlert(e);
                    }
                  }}
                  type="text"
                  placeholder="Enviar alerta ao equipamento..."
                  value={inputValueAlert}
                  className="w-100"
                  disabled={!connectionStabished}
                />
              </Col>

              <Col className="w-100">
                <Button
                  variant="success"
                  onClick={handleSubmitAlert}
                  type="button"
                  className="w-100"
                  disabled={connectionStabished ? false : true}
                >
                  Enviar
                </Button>
              </Col>
            </Row>

          </div>
        </div>
        <div className="col-md-3 d-flex flex-column">
          <div className="p-2 bg-light border flex-grow-1">
            <Row>
              <Col
                className='m-0'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '220px'
                }}
              >
                {!streamInternalCam ? (
                  <Button
                    variant="success"
                    onClick={() => {
                      setStreamInternalCam(true);
                    }}
                    className="w-100"
                    disabled={connectionStabished && equipConfig.webcam ? false : true}
                  >
                    Abrir câmera interna do equipamento
                  </Button>
                ) : imageCamSrc ? (
                  <img
                    alt="Screenshot"
                    src={imageCamSrc}
                    className="w-100"
                  />
                ) : (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <h6 style={{ color: 'black' }}>Aguardando imagem...</h6>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Conected;

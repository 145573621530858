import React from 'react';
import { Alert, SearchLocationInput } from 'components';
import { Card, Row, Col, Button, FloatingLabel, Form } from 'react-bootstrap';

const Forms = props => {
  const { fieldChange, onSubmit, units, setLocation } = props;

  const formatCNPJ = (value) => {
    if (!value) return value;

    const cnpj = value.replace(/\D/g, '');
    if (cnpj.length <= 14) {
      return cnpj
        .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }
    return cnpj;
  };

  const handleCNPJChange = (event) => {
    const rawValue = event.target.value;
    const formattedCNPJ = formatCNPJ(rawValue);
    fieldChange(event, 'cnpj', formattedCNPJ);
  };

  return (
    <Card className='mt-3 p-3'>
      <Row>
        <Col>
          <form>

            <FloatingLabel
              controlId="floatingName"
              label="Nome da Unidade"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Nome da Unidade"
                name="name"
                onChange={event => fieldChange(event, 'name', event.target.value)}
                value={units.name}
                maxLength={100}
              />
            </FloatingLabel>

            <FloatingLabel controlId="floatingCNPJ" label="CNPJ" className="mb-3">
              <Form.Control
                type="text"
                placeholder="CNPJ"
                name="cnpj"
                autoComplete="off"
                onChange={handleCNPJChange}
                value={units.cnpj}
                maxLength={18}
              />
            </FloatingLabel>

            <SearchLocationInput
              setLocation={setLocation}
              addr={units.address}
            />

            <FloatingLabel
              controlId="floatingPhone"
              label="Telefone"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Telefone"
                name="fone"
                autoComplete="off"
                onChange={(event) => fieldChange(event, 'fone', event.target.value)}
                value={units.fone}
                maxLength={30}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingEmail"
              label="E-mail"
              className="mb-3"
            >
              <Form.Control
                type="email"
                placeholder="E-mail"
                name="email"
                autoComplete="off"
                onChange={(event) => fieldChange(event, 'email', event.target.value)}
                value={units.email}
                maxLength={100}
              />
            </FloatingLabel>
          </form>

          <Button
            onClick={() => { onSubmit() }}
            variant="success"
          >
            Salvar
          </Button>

          <Button
            onClick={() => { window.history.back() }}
            variant="secondary"
            className='mx-2'
          >
            Cancelar
          </Button>

        </Col>
      </Row>
    </Card>
  );
};

export default Forms;

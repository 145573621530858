import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Button, Card, Col, Row, FloatingLabel, Form } from 'react-bootstrap';
import EmailSelect from '../EmailSelect/EmailSelect';

const schema = {
  title: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  message: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  url: {
    presence: { allowEmpty: true, message: 'is required' }
  },
  filter: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

const NotificationForm = ({ handleModalAlert, handleAlertModalSuccess, handleAlertModalError }) => {
  const { permission } = JSON.parse(localStorage.getItem('userData'));

  const [emails, setEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);
  useEffect(() => {
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).get("emails");
      data.push("Todos");
      data.push("SIMT");
      data.push("BIMT");
      data.push("CPMT");
      data.push("EEMT");
      data.push("ESMR");
      setEmails(data.filter(i => i));
    })();
  }, []);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();


    if (!formState.values.title) {
      handleModalAlert('Preencha o campo "Titulo"');
      return;
    }

    if (!formState.values.message) {
      handleModalAlert('Preencha o campo "Mensagem"');
      return;
    }

    let filter = selectedEmails;
    if (!(Array.isArray(filter) && filter.length)) {
      handleModalAlert('Preencha o filtro');
      return;
    }

    if (filter.includes("Todos")) {
      filter = [];
    }

    const payload = {}

    let screen = formState.values.url === null || formState.values.url === '' ? "login" : "site"

    payload.data = {
      "title": formState.values.title,
      "body": formState.values.message,
      "screen": screen,
      "url": formState.values.url,
      "id": 12
    }
    payload.notification = {
      "title": formState.values.title,
      "body": formState.values.message
    }

    payload.filter = {
      "list": filter
    }

    let credentials = JSON.parse(localStorage.getItem("userData"));

    payload.email = credentials.email;
    payload.password = credentials.password;

    try {
      await axios(process.env.REACT_APP_API_MOTOMCO, getToken()).post('/notificationapp', payload);
      handleAlertModalSuccess('Notificação enviada com sucesso!');
    } catch (error) {
      console.log(error);
      if (error.response) {
        handleAlertModalError(error.response.data);
      } else {
        handleAlertModalError('Falha ao enviar!');
      }
    }
  };

  const hasError = (field) => {
    return formState.touched[field] && formState.errors[field] ? true : false;
  }

  return (
    <Card className='mt-3 p-3'>
      <Row>
        <Col>
          <form>
            <FloatingLabel
              controlId="floatingInput"
              label="Título"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="title"
                placeholder="Título"
                isInvalid={hasError('title')}
                value={formState.values.title || ''}
                onChange={handleChange}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingMessage"
              label="Mensagem"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="message"
                placeholder="Mensagem"
                isInvalid={hasError('message')}
                value={formState.values.message || ''}
                onChange={handleChange}
              />
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingUrl"
              label="Url"
              className="mb-3"
            >
              <Form.Control
                type="text"
                name="url"
                placeholder="Url"
                isInvalid={hasError('url')}
                value={formState.values.url || ''}
                onChange={handleChange}
              />
            </FloatingLabel>

            <EmailSelect
              emails={emails}
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
            />

            <Button
              className='mt-3'
              disabled={permission?.notifications?.write === true ? false : true}
              onClick={handleSubmit}
              variant="success"
            >
              Enviar
            </Button>
            
          </form>
        </Col>
      </Row>
    </Card>
  );
};

export default NotificationForm;

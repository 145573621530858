import React, { useEffect, useState } from "react";
import axios from 'utils/axios';
import { getToken } from 'actions/sessionActions';
import { Bar } from "react-chartjs-2";
import { timeSince } from "utils/utiuls";
import { Form } from "react-bootstrap";

const CertificadoDash = () => {
  const [equipments, setEquipments] = useState([]);

  const [certificadoLabels, setCertificadoLabels] = useState([]);
  const [certificadoDataset, setCertificadoDataset] = useState([]);

  const [checkLocacao, setCheckLocacao] = useState(false);

  const [certEmDia, setCertEmDia] = useState("");
  const [certAtraso, setCertAtraso] = useState("");

  const processData = (equipments, loc) => {

    let equipamentos = [...equipments];

    if (loc) {
      equipamentos = equipamentos.filter(i => i.mode == "RENTAL")
    }

    const sampleArray = [];
    for (let index = 0; index < equipamentos.length; index++) {
      const element = equipamentos[index];

      var strData = element.dcal;
      if (strData) {
        var d = new Date(strData);

        let time = timeSince(d);

        sampleArray.push(time);
      } else {
        sampleArray.push("NaN");
      }
    }

    setCertEmDia(sampleArray.filter(i => i.includes("mês") || i.includes("meses")).length)
    setCertAtraso(sampleArray.filter(i => i.includes("ano") || i.includes("anos")).length)

    const counts = {};

    sampleArray.forEach((x) => { counts[x] = (counts[x] || 0) + 1; });

    var result = [];

    const keys = Object.keys(counts);

    keys.forEach((key, index) => {
      result.push(`${key}: ${counts[key]}`);
    });

    let meses = result.filter(i => i.includes("mês") || i.includes("meses")).sort((a, b) => { return a.split(" ")[0] - b.split(" ")[0] })
    let anos = result.filter(i => i.includes("ano") || i.includes("anos")).sort((a, b) => { return a.split(" ")[0] - b.split(" ")[0] })
    let finalArray = meses.concat(anos);


    let dataset = finalArray.map(i => i.split(":")[1]);
    setCertificadoDataset(dataset);
    let labels = finalArray.map(i => i.split(":")[0]);
    setCertificadoLabels(labels);
  }

  useEffect(() => {
    axios(process.env.REACT_APP_API_GLOBAL_SERVER, getToken())
      .get('/equipment/all')
      .then((response) => {
        setEquipments(response.data);
        processData(response.data, false);
      });
  }, []);


  return (
    <div>
      <h5 className='mt-3 mb-0'>CERTIFICADOS DE CALIBRAÇÃO</h5>
      <p style={{ fontSize: 12, marginBottom: 3 }}>Certificados em dia: {certEmDia}</p>
      <p style={{ fontSize: 12, marginBottom: 3 }}>Certificados em ATRASO: {certAtraso}</p>

      <Form.Check type="checkbox" inline>
        <Form.Check.Input
          type="checkbox"
          onChange={() => {
            setCheckLocacao(!checkLocacao)
            processData(equipments, !checkLocacao)
          }}
          isValid
          checked={checkLocacao}
        />
        <Form.Check.Label>{'Locação'}</Form.Check.Label>
      </Form.Check>

      <br />

      <Bar
        height={110}
        data={{
          labels: certificadoLabels,
          datasets: [
            {
              label: 'Equipamentos',
              data: certificadoDataset,
              backgroundColor: 'rgba(18, 88, 25, 0.8)',
              borderColor: 'black',
              borderWidth: 1
            }
          ]
        }}
      />
    </div>
  )
}
export default CertificadoDash;
